import React from "react"
import { graphql } from "gatsby"
import { Link } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Layout from "../components/layout"
import Slider from "../components/slider"
import Seo from "../components/seo"
import ReactLinkify from "react-linkify"
import PostList from "../components/post_list_alpha"

const OutlanderKitchen = ({ data }) => {

  const slider_data = data.allMdx.nodes.map((x) =>  x);

  const first_video = slider_data.shift();

  const metaData = [{
    name: "keywords",
    content: first_video ? first_video.frontmatter.keywords : "",
  }];

  const image = getImage(first_video.fields.thumbnail);

  const description = "Watch me make recipes from Theresa Carle-Sanders' Outlander Kitchen cookbook, which includes food mentioned in the popular book series, Outlander, by Diana Gabaldon...and now a television series on Starz!";

  return (
    <Layout>
      <Seo title="Outlander Kitchen" image={image} meta={metaData} description={description}/>
      <h1>Outlander Kitchen</h1>
      <h2>The Official Outlander Companion Cookbook</h2>
      <p>and the second book</p>
      <h2>To The New World And Back Again</h2>
      <p>
        Outlander, originally, is the first in a series of books by Diana Gabaldon.
        It defies categorization, and, indeed, has spawned its own genre; look up 
        Scottish time travel romance in any library and you'll find several books and
        series now categorized as such. But Outlander is more than just a time travel
        romance. It has elements of those, yes, but also historical fiction, political
        intrigue, and, of course, drama. 
      </p>
      <p>
        Now, Outlander is also a television series on Starz. A full five seasons have
        aired as of fall 2021, which takes the viewer through the first five books of
        the series. From Scotland to France, from the 1940s to the 1740s and back. From 
        Scotland to America, with some interesting stops in between, in both the 1960s 
        and the 1760s.
      </p>
      <p>
        In both <a href="http://outlanderkitchen.com/">Outlander Kitchen</a> cookbooks, 
        the author <a href="http://outlanderkitchen.com/about">Theresa Carle-Sanders</a> brings
        us recipes for some of the foods mentioned in the books, and some recipes simply inspired 
        by characters from them. It has garnered criticism from some due to the inclusion of 
        very non-Scottish recipes (like fish tacos!), but as the setting varies so much, that 
        should be expected. She has also made efforts to modernize some of the older recipes, 
        to better appeal to modern palates and use ingredients that are more easily available.
      </p>
    
      {first_video && (
        <div className="featured-video">
          <h2>{first_video.frontmatter.title}</h2>
          <Link to={first_video.fields.slug}>
            {first_video.fields.thumbnail && 
              <GatsbyImage image={getImage(first_video.fields.thumbnail)} alt={first_video.frontmatter.title + ' from ' + first_video.frontmatter.source}/> 
            }
          </Link>
          <figcaption>
            {first_video.frontmatter.description && (
              <ReactLinkify>{first_video.frontmatter.description.split('\n').shift()}</ReactLinkify>
            )}
          </figcaption>
        </div>
      )}
      {slider_data.length > 0 &&  (
      <div className="video-slider">
        <h2>More Recent Videos</h2>
        <Slider data={slider_data} />
      </div>)}
      <div className="all-videos">
        <h2>All Videos</h2>
        <PostList data={data.allMdx.nodes} />
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    allMdx(
      filter: { frontmatter: { keywords: { regex: "/outlander kitchen/" } } }
      sort: { order: DESC, fields: frontmatter___date }
    ) {
      nodes {
        fields {
          youtubeId
          slug
          thumbnail {
            childImageSharp {
              gatsbyImageData(
                quality: 100
                layout: FULL_WIDTH)
            }
          }
        }
        frontmatter {
          title
          source
          date(formatString: "MMMM D, Y")
          video_url
          description
          keywords
        }
      }
    }
  }
`

export default  OutlanderKitchen;
